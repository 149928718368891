import * as React from "react"
import { graphql } from "gatsby"
import { useWindowSize } from "react-use"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Card } from "../components/card"

const MainWrapper = styled.div`
  padding: 40px 0px;
`

const ArticleWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.repeat}, 1fr);
  grid-auto-rows: minmax(150px, auto);
  gap: 1rem;
  max-width: 1360px;
  margin: auto;
`

const MainHeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MainHeading = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 16px;
  width: fit-content;
  border-bottom: 3px dashed #ff7b1c;
`

const IndexPage = ({ data }) => {
  const { authorsJson } = data
  const articles = data?.allMarkdownRemark?.edges
  const { width } = useWindowSize()
  let repeat = 1
  if (width >= 550 && width < 950) {
    repeat = 2
  } else if (width >= 950 && width < 1400) {
    repeat = 3
  } else if (width >= 1400) {
    repeat = 4
  }
  return (
    <Layout>
      <MainWrapper>
        <MainHeadingWrapper>
          <MainHeading>Welcome to Letsaskhow</MainHeading>
        </MainHeadingWrapper>
        <ArticleWrapper width={width} repeat={repeat}>
          {articles?.map(article => {
            const author = authorsJson.authors.find(
              res => res.name === article.node.frontmatter.author
            )
            return (
              <Card
                {...article?.node}
                author={author}
                key={article.node.frontmatter.title}
              />
            )
          })}
        </ArticleWrapper>
      </MainWrapper>
    </Layout>
  )
}

export const Head = () => <Seo title="Welcome to Letsaskhow" isHome={true} />

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          frontmatter {
            date
            slug
            title
            author
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            excerpt
          }
        }
      }
    }
    authorsJson {
      authors {
        bio
        name
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default IndexPage
