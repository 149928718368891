import React from "react"
import styled from "styled-components"
import { FiArrowRight } from "react-icons/fi"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Wrapper = styled.div`
  border: 1px solid transparent;
  min-height: 380px;
  margin-bottom: 40px;
`

export const Card = ({ frontmatter, author: authorObj }) => {
  const title = frontmatter?.title
  const link = frontmatter?.slug
  const author = frontmatter?.author
  const image = getImage(frontmatter?.image)
  const excerpt = frontmatter?.excerpt
  const authorImage = getImage(authorObj.image)
  return (
    <Wrapper>
      <GatsbyImage
        image={image}
        alt={frontmatter?.title}
        style={{ height: "220px" }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <GatsbyImage
          image={authorImage}
          alt={author}
          style={{
            height: "48px",
            width: "48px",
            borderRadius: "50%",
            backgroundColor: "lightblue",
            marginRight: "16px",
          }}
        />
        <div>{author}</div>
      </div>
      <h3 style={{ lineHeight: "1.25" }}>{title}</h3>
      <p style={{ lineHeight: "1.5" }}>{excerpt}</p>
      <Link to={link}>
        Read <FiArrowRight size="16" />
      </Link>
    </Wrapper>
  )
}
